import { defineStore } from 'pinia'
import { Event } from '../api.ts'
import {
    CaptureEventModel,
    ICaptureEventModel,
} from '../models/CaptureEventModel.ts'
import { iamEventsByAuthenticationKeyAndId } from '../graphql/queries.extend.ts'
import { execute } from '@/utils/graphql.ts'

interface State {
    event?: ICaptureEventModel
    isLoading: boolean
}

export const useCaptureEventStore = defineStore('CaptureEventStore', {
    state: (): State => ({
        /** @type: ICaptureEventModel[] */
        event: undefined,
        /** @type: boolean */
        isLoading: true,
    }),
    getters: {},
    actions: {
        async fetchEvent(eventId: string, authenticationKey: string) {
            try {
                this.isLoading = true

                const response = await execute(
                    iamEventsByAuthenticationKeyAndId,
                    {
                        id: { eq: eventId },
                        authenticationKey: authenticationKey,
                    },
                    'iam'
                )

                if (!response.success || !response.data) {
                    return undefined
                }

                const events = response.data.eventsByAuthenticationKeyAndId

                if (!events || !events.items) {
                    return undefined
                }

                if (events.items.length === 0) {
                    return undefined
                }

                this.event = CaptureEventModel.fromDTO(events.items[0] as Event)

                console.log(this.event)
            } catch (error) {
                console.error(error)
            } finally {
                this.isLoading = false
            }
        },
    },
})
