/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../api'
type GeneratedQuery<InputType, OutputType> = string & {
    __generatedQueryInput: InputType
    __generatedQueryOutput: OutputType
}

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    owner
    name
    email
    phone
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      owner
      name
      email
      phone
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>
export const getTransaction = /* GraphQL */ `query GetTransaction($id: ID!) {
  getTransaction(id: $id) {
    owner
    amount
    associatedId
    transactionType
    transactionDate
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetTransactionQueryVariables,
    APITypes.GetTransactionQuery
>
export const listTransactions = /* GraphQL */ `query ListTransactions(
  $filter: ModelTransactionFilterInput
  $limit: Int
  $nextToken: String
) {
  listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      owner
      amount
      associatedId
      transactionType
      transactionDate
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListTransactionsQueryVariables,
    APITypes.ListTransactionsQuery
>
export const getEvent = /* GraphQL */ `query GetEvent($id: ID!) {
  getEvent(id: $id) {
    id
    name
    description
    guestMessage
    venueId
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    status
    transactionId
    photos {
      nextToken
      __typename
    }
    userFavorites {
      nextToken
      __typename
    }
    albums {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetEventQueryVariables, APITypes.GetEventQuery>
export const listEvents = /* GraphQL */ `query ListEvents(
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListEventsQueryVariables, APITypes.ListEventsQuery>
export const eventsByAuthenticationKeyAndId =
    /* GraphQL */ `query EventsByAuthenticationKeyAndId(
  $authenticationKey: String!
  $id: ModelIDKeyConditionInput
  $sortDirection: ModelSortDirection
  $filter: ModelEventFilterInput
  $limit: Int
  $nextToken: String
) {
  eventsByAuthenticationKeyAndId(
    authenticationKey: $authenticationKey
    id: $id
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
        APITypes.EventsByAuthenticationKeyAndIdQueryVariables,
        APITypes.EventsByAuthenticationKeyAndIdQuery
    >
export const getVenue = /* GraphQL */ `query GetVenue($id: ID!) {
  getVenue(id: $id) {
    name
    address
    city
    county
    country
    postcode
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetVenueQueryVariables, APITypes.GetVenueQuery>
export const listVenues = /* GraphQL */ `query ListVenues(
  $filter: ModelVenueFilterInput
  $limit: Int
  $nextToken: String
) {
  listVenues(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      name
      address
      city
      county
      country
      postcode
      id
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListVenuesQueryVariables, APITypes.ListVenuesQuery>
export const getEventPhoto = /* GraphQL */ `query GetEventPhoto($id: ID!) {
  getEventPhoto(id: $id) {
    id
    eventId
    event {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    imagePath
    thumbnailPath
    height
    width
    albumPhotos {
      nextToken
      __typename
    }
    userFavorites {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetEventPhotoQueryVariables,
    APITypes.GetEventPhotoQuery
>
export const listEventPhotos = /* GraphQL */ `query ListEventPhotos(
  $filter: ModelEventPhotoFilterInput
  $limit: Int
  $nextToken: String
) {
  listEventPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListEventPhotosQueryVariables,
    APITypes.ListEventPhotosQuery
>
export const eventPhotosByEventId = /* GraphQL */ `query EventPhotosByEventId(
  $eventId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelEventPhotoFilterInput
  $limit: Int
  $nextToken: String
) {
  eventPhotosByEventId(
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.EventPhotosByEventIdQueryVariables,
    APITypes.EventPhotosByEventIdQuery
>
export const getAlbum = /* GraphQL */ `query GetAlbum($id: ID!) {
  getAlbum(id: $id) {
    eventId
    event {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    name
    description
    photos {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<APITypes.GetAlbumQueryVariables, APITypes.GetAlbumQuery>
export const listAlbums = /* GraphQL */ `query ListAlbums(
  $filter: ModelAlbumFilterInput
  $limit: Int
  $nextToken: String
) {
  listAlbums(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      eventId
      name
      description
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListAlbumsQueryVariables, APITypes.ListAlbumsQuery>
export const albumsByEventId = /* GraphQL */ `query AlbumsByEventId(
  $eventId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAlbumFilterInput
  $limit: Int
  $nextToken: String
) {
  albumsByEventId(
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      eventId
      name
      description
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.AlbumsByEventIdQueryVariables,
    APITypes.AlbumsByEventIdQuery
>
export const getAlbumPhoto = /* GraphQL */ `query GetAlbumPhoto($id: ID!) {
  getAlbumPhoto(id: $id) {
    albumId
    album {
      eventId
      name
      description
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    image {
      id
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      owner
      __typename
    }
    id
    createdAt
    updatedAt
    eventPhotoAlbumPhotosId
    albumPhotosId
    owner
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetAlbumPhotoQueryVariables,
    APITypes.GetAlbumPhotoQuery
>
export const listAlbumPhotos = /* GraphQL */ `query ListAlbumPhotos(
  $filter: ModelAlbumPhotoFilterInput
  $limit: Int
  $nextToken: String
) {
  listAlbumPhotos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      albumId
      id
      createdAt
      updatedAt
      eventPhotoAlbumPhotosId
      albumPhotosId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListAlbumPhotosQueryVariables,
    APITypes.ListAlbumPhotosQuery
>
export const albumPhotosByAlbumId = /* GraphQL */ `query AlbumPhotosByAlbumId(
  $albumId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelAlbumPhotoFilterInput
  $limit: Int
  $nextToken: String
) {
  albumPhotosByAlbumId(
    albumId: $albumId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      albumId
      id
      createdAt
      updatedAt
      eventPhotoAlbumPhotosId
      albumPhotosId
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.AlbumPhotosByAlbumIdQueryVariables,
    APITypes.AlbumPhotosByAlbumIdQuery
>
export const getUserFavorite = /* GraphQL */ `query GetUserFavorite($id: ID!) {
  getUserFavorite(id: $id) {
    eventId
    event {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    eventPhotoId
    photo {
      id
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      owner
      __typename
    }
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedQuery<
    APITypes.GetUserFavoriteQueryVariables,
    APITypes.GetUserFavoriteQuery
>
export const listUserFavorites = /* GraphQL */ `query ListUserFavorites(
  $filter: ModelUserFavoriteFilterInput
  $limit: Int
  $nextToken: String
) {
  listUserFavorites(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      eventId
      eventPhotoId
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
    APITypes.ListUserFavoritesQueryVariables,
    APITypes.ListUserFavoritesQuery
>
export const userFavoritesByEventId =
    /* GraphQL */ `query UserFavoritesByEventId(
  $eventId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFavoriteFilterInput
  $limit: Int
  $nextToken: String
) {
  userFavoritesByEventId(
    eventId: $eventId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      eventId
      eventPhotoId
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
        APITypes.UserFavoritesByEventIdQueryVariables,
        APITypes.UserFavoritesByEventIdQuery
    >
export const userFavoritesByEventPhotoId =
    /* GraphQL */ `query UserFavoritesByEventPhotoId(
  $eventPhotoId: ID!
  $sortDirection: ModelSortDirection
  $filter: ModelUserFavoriteFilterInput
  $limit: Int
  $nextToken: String
) {
  userFavoritesByEventPhotoId(
    eventPhotoId: $eventPhotoId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      eventId
      eventPhotoId
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
        APITypes.UserFavoritesByEventPhotoIdQueryVariables,
        APITypes.UserFavoritesByEventPhotoIdQuery
    >
