/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from '../api'
type GeneratedMutation<InputType, OutputType> = string & {
    __generatedMutationInput: InputType
    __generatedMutationOutput: OutputType
}

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    owner
    name
    email
    phone
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateUserMutationVariables,
    APITypes.CreateUserMutation
>
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    owner
    name
    email
    phone
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateUserMutationVariables,
    APITypes.UpdateUserMutation
>
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    owner
    name
    email
    phone
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteUserMutationVariables,
    APITypes.DeleteUserMutation
>
export const createTransaction = /* GraphQL */ `mutation CreateTransaction(
  $input: CreateTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  createTransaction(input: $input, condition: $condition) {
    owner
    amount
    associatedId
    transactionType
    transactionDate
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateTransactionMutationVariables,
    APITypes.CreateTransactionMutation
>
export const updateTransaction = /* GraphQL */ `mutation UpdateTransaction(
  $input: UpdateTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  updateTransaction(input: $input, condition: $condition) {
    owner
    amount
    associatedId
    transactionType
    transactionDate
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateTransactionMutationVariables,
    APITypes.UpdateTransactionMutation
>
export const deleteTransaction = /* GraphQL */ `mutation DeleteTransaction(
  $input: DeleteTransactionInput!
  $condition: ModelTransactionConditionInput
) {
  deleteTransaction(input: $input, condition: $condition) {
    owner
    amount
    associatedId
    transactionType
    transactionDate
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteTransactionMutationVariables,
    APITypes.DeleteTransactionMutation
>
export const createEvent = /* GraphQL */ `mutation CreateEvent(
  $input: CreateEventInput!
  $condition: ModelEventConditionInput
) {
  createEvent(input: $input, condition: $condition) {
    id
    name
    description
    guestMessage
    venueId
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    status
    transactionId
    photos {
      nextToken
      __typename
    }
    userFavorites {
      nextToken
      __typename
    }
    albums {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateEventMutationVariables,
    APITypes.CreateEventMutation
>
export const updateEvent = /* GraphQL */ `mutation UpdateEvent(
  $input: UpdateEventInput!
  $condition: ModelEventConditionInput
) {
  updateEvent(input: $input, condition: $condition) {
    id
    name
    description
    guestMessage
    venueId
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    status
    transactionId
    photos {
      nextToken
      __typename
    }
    userFavorites {
      nextToken
      __typename
    }
    albums {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateEventMutationVariables,
    APITypes.UpdateEventMutation
>
export const deleteEvent = /* GraphQL */ `mutation DeleteEvent(
  $input: DeleteEventInput!
  $condition: ModelEventConditionInput
) {
  deleteEvent(input: $input, condition: $condition) {
    id
    name
    description
    guestMessage
    venueId
    eventStartDateTime
    eventEndDateTime
    imageBase64
    authenticationKey
    status
    transactionId
    photos {
      nextToken
      __typename
    }
    userFavorites {
      nextToken
      __typename
    }
    albums {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteEventMutationVariables,
    APITypes.DeleteEventMutation
>
export const createVenue = /* GraphQL */ `mutation CreateVenue(
  $input: CreateVenueInput!
  $condition: ModelVenueConditionInput
) {
  createVenue(input: $input, condition: $condition) {
    name
    address
    city
    county
    country
    postcode
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateVenueMutationVariables,
    APITypes.CreateVenueMutation
>
export const updateVenue = /* GraphQL */ `mutation UpdateVenue(
  $input: UpdateVenueInput!
  $condition: ModelVenueConditionInput
) {
  updateVenue(input: $input, condition: $condition) {
    name
    address
    city
    county
    country
    postcode
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateVenueMutationVariables,
    APITypes.UpdateVenueMutation
>
export const deleteVenue = /* GraphQL */ `mutation DeleteVenue(
  $input: DeleteVenueInput!
  $condition: ModelVenueConditionInput
) {
  deleteVenue(input: $input, condition: $condition) {
    name
    address
    city
    county
    country
    postcode
    id
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteVenueMutationVariables,
    APITypes.DeleteVenueMutation
>
export const createEventPhoto = /* GraphQL */ `mutation CreateEventPhoto(
  $input: CreateEventPhotoInput!
  $condition: ModelEventPhotoConditionInput
) {
  createEventPhoto(input: $input, condition: $condition) {
    id
    eventId
    event {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    imagePath
    thumbnailPath
    height
    width
    albumPhotos {
      nextToken
      __typename
    }
    userFavorites {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateEventPhotoMutationVariables,
    APITypes.CreateEventPhotoMutation
>
export const updateEventPhoto = /* GraphQL */ `mutation UpdateEventPhoto(
  $input: UpdateEventPhotoInput!
  $condition: ModelEventPhotoConditionInput
) {
  updateEventPhoto(input: $input, condition: $condition) {
    id
    eventId
    event {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    imagePath
    thumbnailPath
    height
    width
    albumPhotos {
      nextToken
      __typename
    }
    userFavorites {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateEventPhotoMutationVariables,
    APITypes.UpdateEventPhotoMutation
>
export const deleteEventPhoto = /* GraphQL */ `mutation DeleteEventPhoto(
  $input: DeleteEventPhotoInput!
  $condition: ModelEventPhotoConditionInput
) {
  deleteEventPhoto(input: $input, condition: $condition) {
    id
    eventId
    event {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    imagePath
    thumbnailPath
    height
    width
    albumPhotos {
      nextToken
      __typename
    }
    userFavorites {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteEventPhotoMutationVariables,
    APITypes.DeleteEventPhotoMutation
>
export const createAlbum = /* GraphQL */ `mutation CreateAlbum(
  $input: CreateAlbumInput!
  $condition: ModelAlbumConditionInput
) {
  createAlbum(input: $input, condition: $condition) {
    eventId
    event {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    name
    description
    photos {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateAlbumMutationVariables,
    APITypes.CreateAlbumMutation
>
export const updateAlbum = /* GraphQL */ `mutation UpdateAlbum(
  $input: UpdateAlbumInput!
  $condition: ModelAlbumConditionInput
) {
  updateAlbum(input: $input, condition: $condition) {
    eventId
    event {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    name
    description
    photos {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateAlbumMutationVariables,
    APITypes.UpdateAlbumMutation
>
export const deleteAlbum = /* GraphQL */ `mutation DeleteAlbum(
  $input: DeleteAlbumInput!
  $condition: ModelAlbumConditionInput
) {
  deleteAlbum(input: $input, condition: $condition) {
    eventId
    event {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    name
    description
    photos {
      nextToken
      __typename
    }
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteAlbumMutationVariables,
    APITypes.DeleteAlbumMutation
>
export const createAlbumPhoto = /* GraphQL */ `mutation CreateAlbumPhoto(
  $input: CreateAlbumPhotoInput!
  $condition: ModelAlbumPhotoConditionInput
) {
  createAlbumPhoto(input: $input, condition: $condition) {
    albumId
    album {
      eventId
      name
      description
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    image {
      id
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      owner
      __typename
    }
    id
    createdAt
    updatedAt
    eventPhotoAlbumPhotosId
    albumPhotosId
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateAlbumPhotoMutationVariables,
    APITypes.CreateAlbumPhotoMutation
>
export const updateAlbumPhoto = /* GraphQL */ `mutation UpdateAlbumPhoto(
  $input: UpdateAlbumPhotoInput!
  $condition: ModelAlbumPhotoConditionInput
) {
  updateAlbumPhoto(input: $input, condition: $condition) {
    albumId
    album {
      eventId
      name
      description
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    image {
      id
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      owner
      __typename
    }
    id
    createdAt
    updatedAt
    eventPhotoAlbumPhotosId
    albumPhotosId
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateAlbumPhotoMutationVariables,
    APITypes.UpdateAlbumPhotoMutation
>
export const deleteAlbumPhoto = /* GraphQL */ `mutation DeleteAlbumPhoto(
  $input: DeleteAlbumPhotoInput!
  $condition: ModelAlbumPhotoConditionInput
) {
  deleteAlbumPhoto(input: $input, condition: $condition) {
    albumId
    album {
      eventId
      name
      description
      id
      createdAt
      updatedAt
      owner
      __typename
    }
    image {
      id
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      owner
      __typename
    }
    id
    createdAt
    updatedAt
    eventPhotoAlbumPhotosId
    albumPhotosId
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteAlbumPhotoMutationVariables,
    APITypes.DeleteAlbumPhotoMutation
>
export const createUserFavorite = /* GraphQL */ `mutation CreateUserFavorite(
  $input: CreateUserFavoriteInput!
  $condition: ModelUserFavoriteConditionInput
) {
  createUserFavorite(input: $input, condition: $condition) {
    eventId
    event {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    eventPhotoId
    photo {
      id
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      owner
      __typename
    }
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.CreateUserFavoriteMutationVariables,
    APITypes.CreateUserFavoriteMutation
>
export const updateUserFavorite = /* GraphQL */ `mutation UpdateUserFavorite(
  $input: UpdateUserFavoriteInput!
  $condition: ModelUserFavoriteConditionInput
) {
  updateUserFavorite(input: $input, condition: $condition) {
    eventId
    event {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    eventPhotoId
    photo {
      id
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      owner
      __typename
    }
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.UpdateUserFavoriteMutationVariables,
    APITypes.UpdateUserFavoriteMutation
>
export const deleteUserFavorite = /* GraphQL */ `mutation DeleteUserFavorite(
  $input: DeleteUserFavoriteInput!
  $condition: ModelUserFavoriteConditionInput
) {
  deleteUserFavorite(input: $input, condition: $condition) {
    eventId
    event {
      id
      name
      description
      guestMessage
      venueId
      eventStartDateTime
      eventEndDateTime
      imageBase64
      authenticationKey
      status
      transactionId
      createdAt
      updatedAt
      owner
      __typename
    }
    eventPhotoId
    photo {
      id
      eventId
      imagePath
      thumbnailPath
      height
      width
      createdAt
      updatedAt
      owner
      __typename
    }
    id
    createdAt
    updatedAt
    owner
    __typename
  }
}
` as GeneratedMutation<
    APITypes.DeleteUserFavoriteMutationVariables,
    APITypes.DeleteUserFavoriteMutation
>
