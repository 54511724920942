import { defineStore } from 'pinia'
import { Event, EventPhoto } from '../api.ts'
import {
    eventPhotosByEventId,
    getEvent,
    listEvents,
} from '../graphql/queries.ts'
import { createEvent, deleteEvent, updateEvent } from '../graphql/mutations.ts'
import { v4 as uuidv4 } from 'uuid'
import { EventModel, IEventModel } from '../models/EventModel.ts'
import * as subscriptions from '../graphql/subscriptions'
import { Subscription } from 'rxjs'
import { execute } from '@/utils/graphql.ts'
import { generateClient } from 'aws-amplify/api'

interface EventStoreState {
    events: IEventModel[]
    isLoading: boolean
}

export const useEventStore = defineStore('EventStore', {
    state: (): EventStoreState => ({
        /** @type: EventModel[] */
        events: [],
        isLoading: false,
    }),
    getters: {
        count: (state: EventStoreState) => state.events.length,
        getEventById: (state: EventStoreState) => (eventId: string) => {
            return state.events.find((event) => event.eventId === eventId)
        },
    },
    actions: {
        async createEvent(name: string, description: string) {
            const result = await execute(createEvent, {
                input: {
                    id: uuidv4().toString(),
                    name: name,
                    description: description,
                },
            })

            if (!result.success || !result.data) {
                throw new Error('Failed to create event')
            }

            const newEvent = result.data.createEvent as Event

            this.events = [EventModel.fromDTO(newEvent), ...this.events]

            return newEvent
        },
        async fetchEvents() {
            this.isLoading = true

            const result = await execute(listEvents)

            if (!result.success || !result.data || !result.data.listEvents) {
                this.isLoading = false
                return []
            }

            const items = result.data.listEvents.items || []

            // Filter out null values and cast to Event[]
            const validItems = items.filter(
                (item): item is Event => item !== null
            )

            this.events = validItems
                .sort((a, b) => {
                    return (
                        new Date(b.createdAt).getTime() -
                        new Date(a.createdAt).getTime()
                    )
                })
                .map((x) => EventModel.fromDTO(x))

            this.isLoading = false
            return this.events
        },
        async fetchUpcomingEvents() {
            this.isLoading = true

            try {
                const currentDate = new Date()
                const currentDateISOString = currentDate.toISOString()
                const eventStartDate = new Date(
                    currentDate.setDate(currentDate.getDate() + 7)
                ).toISOString()

                const filter = {
                    eventStartDateTime: {
                        between: [currentDateISOString, eventStartDate],
                    },
                }

                const result = await execute(listEvents, { filter })

                if (
                    !result.success ||
                    !result.data ||
                    !result.data.listEvents
                ) {
                    return []
                }

                const items = result.data.listEvents.items || []
                // Filter out null values and cast to Event[]
                const validItems = items.filter(
                    (item): item is Event => item !== null
                )

                return validItems
                    .sort((a, b) => {
                        return (
                            new Date(b.createdAt).getTime() -
                            new Date(a.createdAt).getTime()
                        )
                    })
                    .map((x) => EventModel.fromDTO(x))
            } catch (error) {
                console.error(error)
                return []
            } finally {
                this.isLoading = false
            }
        },
        async fetchRecentlyEndedEvents() {
            this.isLoading = true

            try {
                const currentDate = new Date()
                const currentDateISOString = currentDate.toISOString()
                const eventEndDate = new Date(
                    currentDate.setDate(currentDate.getDate() - 7)
                ).toISOString()

                const filter = {
                    eventEndDateTime: {
                        between: [eventEndDate, currentDateISOString],
                    },
                }

                const result = await execute(listEvents, { filter })

                if (
                    !result.success ||
                    !result.data ||
                    !result.data.listEvents
                ) {
                    return []
                }

                const items = result.data.listEvents.items || []
                // Filter out null values and cast to Event[]
                const validItems = items.filter(
                    (item): item is Event => item !== null
                )

                return validItems
                    .sort((a, b) => {
                        return (
                            new Date(b.createdAt).getTime() -
                            new Date(a.createdAt).getTime()
                        )
                    })
                    .map((x) => EventModel.fromDTO(x))
            } catch (error) {
                console.error(error)
                return []
            } finally {
                this.isLoading = false
            }
        },
    },
})

// Client for subscriptions
const client = generateClient({ authMode: 'userPool' })

interface EventStoreScopedState {
    event: IEventModel | undefined
    eventPhotos: EventPhoto[]
    eventPhotoSubscription: Subscription | undefined
    eventLoading: boolean
    photosLoading: boolean
}

export function useScopedEventStore(eventId: string) {
    const store = defineStore(`feature/events::${eventId}`, {
        state: (): EventStoreScopedState => ({
            event: undefined,
            eventPhotos: [],
            eventPhotoSubscription: undefined,
            eventLoading: false,
            photosLoading: false,
        }),
        getters: {
            eventPhotosCount: (state) => state.eventPhotos.length,
        },
        actions: {
            async updateEvent(
                eventId: string,
                name: string,
                description: string,
                startDate: string,
                endDate: string,
                authenticationKey: string
            ) {
                const response = await execute(updateEvent, {
                    input: {
                        id: eventId,
                        name: name,
                        description: description,
                        eventStartDateTime: startDate,
                        eventEndDateTime: endDate,
                        authenticationKey: authenticationKey,
                    },
                })

                if (!response.success || !response.data) {
                    throw new Error('Failed to update event')
                }

                const eventModel = EventModel.fromDTO(
                    response.data.updateEvent as Event
                )

                this.event = eventModel

                return eventModel
            },
            async fetchEvent(): Promise<EventModel | undefined> {
                try {
                    this.eventLoading = true

                    const response = await execute(getEvent, {
                        id: eventId,
                    })

                    if (
                        !response.success ||
                        !response.data ||
                        !response.data.getEvent
                    ) {
                        return undefined
                    }

                    const eventModel = EventModel.fromDTO(
                        response.data.getEvent as Event
                    )

                    this.event = eventModel

                    return eventModel
                } catch (error) {
                    console.error(error)
                    return undefined
                } finally {
                    this.eventLoading = false
                }
            },
            async fetchEventPhotos() {
                try {
                    this.photosLoading = true

                    const result = await execute(eventPhotosByEventId, {
                        eventId: eventId,
                        limit: 1000,
                    })

                    if (
                        !result.success ||
                        !result.data ||
                        !result.data.eventPhotosByEventId
                    ) {
                        this.eventPhotos = []
                        return []
                    }

                    const photos = result.data.eventPhotosByEventId.items || []
                    // Filter out null values and cast to EventPhoto[]
                    const validPhotos = photos.filter(
                        (item): item is EventPhoto => item !== null
                    )

                    this.eventPhotos = validPhotos

                    return this.eventPhotos
                } catch (error) {
                    console.error(error)
                    this.eventPhotos = []
                    return []
                } finally {
                    this.photosLoading = false
                }
            },
            async deleteEvent() {
                try {
                    const result = await execute(deleteEvent, {
                        input: {
                            id: eventId,
                        },
                    })

                    if (!result.success) {
                        throw new Error('Failed to delete event')
                    }

                    return true
                } catch (error) {
                    console.error(error)
                    return false
                }
            },
            subscribeToPhotos() {
                if (this.eventPhotoSubscription) {
                    return
                }

                const filter = {
                    filter: {
                        eventId: { eq: eventId },
                    },
                }

                // Use client.graphql directly for subscriptions as it returns an Observable
                const observable = client.graphql({
                    query: subscriptions.onCreateEventPhoto,
                    variables: filter,
                })

                // Subscribe to the observable
                this.eventPhotoSubscription = observable.subscribe({
                    next: (result) => {
                        const newPhoto = result.data?.onCreateEventPhoto
                        if (newPhoto) {
                            this.eventPhotos.push(newPhoto as EventPhoto)
                        }
                    },
                    error: (error) => {
                        console.error('Subscription error:', error)
                    },
                })
            },
            unsubscribeToPhotos() {
                if (this.eventPhotoSubscription) {
                    this.eventPhotoSubscription.unsubscribe()
                    this.eventPhotoSubscription = undefined
                }
            },
        },
    })

    return store()
}
