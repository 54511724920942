<script setup lang="ts">
import { onMounted, ref } from 'vue'
import router from '@/router.ts'
import { useAuthenticator } from '@aws-amplify/ui-vue'
import { getCurrentUser } from 'aws-amplify/auth'
import { useUIStore } from '@stores/UIStore.ts'
import NavigationLink from '@components/navigation/NavigationLink.vue'

const username = ref<string | undefined>('')
const uiStore = useUIStore()

onMounted(async () => {
    const currentUser = await getCurrentUser()
    username.value = currentUser.signInDetails?.loginId
})

const auth = useAuthenticator()

const handleSignOut = async () => {
    await auth.signOut()
    await router.push({ name: 'Home' })
}

const toggleSidebar = (event: MouseEvent) => {
    if ((event.target as HTMLElement).closest('a, button')) {
        return
    }

    uiStore.isSidebarCollapsed = !uiStore.isSidebarCollapsed
}

const getLogo = () => {
    if (uiStore.isSidebarCollapsed) {
        return '/logo_icon.png'
    }
    return '/logo_long.png'
}
</script>
<template>
    <div
        id="main-sidebar"
        @click="toggleSidebar"
        :class="[
            'h-dvh lg:block flex-shrink-0 absolute lg:static left-0 top-0 z-1 border-right-1 select-none animation-duration-300 animation-ease-in-out bg-white-alpha-50 border-white-alpha-30 cursor-pointer',
            uiStore.isSidebarCollapsed ? 'lg:w-20' : 'lg:w-56',
        ]"
        style="backdrop-filter: blur(10px)"
    >
        <div
            class="flex flex-column h-full bg-gray-50 border-right-2 border-gray-200"
        >
            <div
                :class="[
                    'flex align-items-center px-3 flex-shrink-0 border-bottom-2 bg-gray-100 border-gray-200 relative',
                    uiStore.isSidebarCollapsed ? 'h-20' : 'h-28',
                ]"
                style=""
            >
                <img
                    id="main-sidebar-logo"
                    alt="Image"
                    :class="[
                        'my-4 animation-duration-300 animation-ease-in-out',
                        uiStore.isSidebarCollapsed ? 'h-10' : 'h-16',
                    ]"
                    :src="getLogo()"
                />
                <Button
                    class="absolute -bottom-4 -right-4 border-gray-200 bg-gray-100 text-black font-bold block p-1 h-8"
                    @click="
                        uiStore.isSidebarCollapsed = !uiStore.isSidebarCollapsed
                    "
                >
                    <span class="pi-angle-left pi p-0 text-sm"></span>
                    <span class="pi-angle-right pi p-0 text-sm"></span>
                </Button>
            </div>
            <div class="overflow-y-auto mt-3 bg-gray-50">
                <ul class="list-none px-0 py-1 m-0">
                    <li>
                        <ul class="list-none p-0 m-0 overflow-hidden">
                            <li>
                                <NavigationLink
                                    target-name="Home"
                                    icon="home"
                                    label="Home"
                                />
                            </li>
                            <li>
                                <NavigationLink
                                    target-name="EventList"
                                    icon="th-large"
                                    label="Events"
                                />
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            <div
                class="mt-auto border-top-2 border-gray-200 bg-gray-50 relative"
            >
                <Button
                    v-styleclass="{
                        selector: '#profile-navigation',
                        enterFromClass: 'hidden',
                        enterActiveClass: 'slidedown',
                        leaveToClass: 'hidden',
                        leaveActiveClass: 'slideup',
                    }"
                    class="absolute -top-5 -right-4 border-gray-200 bg-gray-100 text-black font-bold p-1 w-2rem flex flex-column gap-0"
                >
                    <span class="pi-angle-up pi p-0 text-sm"></span>
                    <span class="pi-angle-down pi p-0 text-sm"></span>
                </Button>
                <ul
                    id="profile-navigation"
                    class="list-none px-0 py-1 m-0 hidden animation-duration-150 animation-ease-in-out overflow-hidden"
                >
                    <li>
                        <NavigationLink
                            target-name="Profile"
                            icon="user"
                            label="Profile"
                        />
                    </li>
                    <li>
                        <a
                            v-ripple
                            :class="[
                                'sidebar-nav px-3 py-2 flex align-items-center my-2 cursor-pointer transition-duration-150 transition-colors p-ripple text-black border-0 hover:bg-amber-100 hover:text-black',
                            ]"
                            @click="handleSignOut"
                        >
                            <i
                                :class="[
                                    'pi pi-sign-out text-2xl',
                                    uiStore.isSidebarCollapsed
                                        ? 'ml-2'
                                        : 'mr-2',
                                ]"
                            ></i>
                            <span v-if="!uiStore.isSidebarCollapsed"
                                >Sign Out</span
                            >
                        </a>
                    </li>
                </ul>
                <div class="relative">
                    <a
                        v-ripple
                        v-styleclass="{
                            selector: '#profile-navigation',
                            enterFromClass: 'hidden',
                            enterActiveClass: 'slidedown',
                            leaveToClass: 'hidden',
                            leaveActiveClass: 'slideup',
                        }"
                        class="p-2 pb-3 flex align-items-center text-black cursor-pointer transition-duration-150 transition-colors p-ripple relative"
                    >
                        <Avatar
                            id="sidebar-avatar"
                            image="/amyelsner.png"
                            :class="[
                                'mr-2 flex-grow-0 flex-shrink-0',
                                uiStore.isSidebarCollapsed ? 'ml-2' : '',
                            ]"
                            size="large"
                            shape="circle"
                        />
                        <span
                            class="text-sm flex-grow-0 text-ellipsis overflow-hidden"
                            v-if="!uiStore.isSidebarCollapsed"
                        >
                            {{ username }}
                        </span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
#main-sidebar {
    transition: width 0.3s ease;
}

#main-sidebar-logo {
    transition: height 0.3s ease;
}

#sidebar-avatar {
    transition: margin-left 0.3s ease;
}

.router-link-active {
    font-weight: 500;
}
</style>
