<script setup lang="ts">
import { ref } from 'vue'
import NewEventModal from '@components/NewEventModal.vue'
import { useEventStore } from '@stores/EventStore.ts'

const eventStore = useEventStore()
const createEventDialogVisible = ref(false)
</script>

<template>
    <NewEventModal
        v-model:visible="createEventDialogVisible"
        @eventCreated="(_) => eventStore.fetchEvents()"
    />
    <div
        class="flex w-full justify-content-between align-items-center flex-column md:flex-row"
    >
        <div>
            <h1 class="mt-0 mb-0 text-2xl text-slate-500 font-normal">
                Events
            </h1>
            <p class="text-sm text-slate-400 m-0">
                View and manage your events
            </p>
        </div>
        <div class="flex gap-3 mt-3 md:mt-0">
            <Button
                @click="createEventDialogVisible = true"
                v-ripple
                rounded
                class=""
                label="New event"
            >
                <template #icon="{ class: className }">
                    <i :class="className + ' pi pi-plus mr-2'"></i>
                </template>
            </Button>
        </div>
    </div>
</template>

<style scoped></style>
