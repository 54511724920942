<script setup lang="ts">
import Galleria from 'primevue/galleria'
import { EventPhoto } from '@/api'
import { ref, watch } from 'vue'
import { useEventScopedAlbumStore } from '@stores/AlbumStore'
import { GalleryType, IEventModel } from '@models/EventModel'
import { useUserFavoritesStore } from '@stores/UserFavoritesStore'

interface GalleryProps {
    event: IEventModel
    images: EventPhoto[] | undefined
    galleryType?: GalleryType
    selectable?: boolean
}

const props = defineProps<GalleryProps>()
const emit = defineEmits<{
    (e: 'imagesLoaded', imageCount: number): void
}>()

const albumStore = useEventScopedAlbumStore(props.event.eventId)
const favoritesStore = useUserFavoritesStore()
const gridItems = ref<{ spans: number; image: EventPhoto }[]>([])

const getCdnUrl = (path: string) => {
    return `${import.meta.env.VITE_CDN_URL}/${path}`
}

const downloadImage = async (image: EventPhoto) => {
    try {
        const response = await fetch(getCdnUrl(image.imagePath))
        const blob = await response.blob()
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.href = url
        a.download = `snap-pix-${image.id}.jpg`
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
    } catch (error) {
        console.error('Error downloading image:', error)
    }
}

const activeIndex = ref(0)
const displayGallery = ref(false)

// Function to calculate grid spans based on aspect ratio
const calculateSpans = (image: HTMLImageElement): number => {
    const aspectRatio = image.naturalWidth / image.naturalHeight
    // Landscape images (wider than tall)
    if (aspectRatio > 1.2) return 2 // Take up 2 columns
    // Portrait images (taller than wide)
    if (aspectRatio < 0.8) return 1 // Take up 1 column
    // Nearly square images
    return 1
}

const onImageLoad = async (event: Event, index: number) => {
    const image = event.target as HTMLImageElement

    // Wait for image to fully load
    if (!image.complete) {
        await new Promise((resolve) => {
            image.onload = resolve
        })
    }

    const spans = calculateSpans(image)
    gridItems.value[index] = {
        spans,
        image: props.images![index],
    }

    // Set proper aspect ratio container
    const wrapper = image.parentElement
    if (wrapper) {
        wrapper.style.gridColumn = `span ${spans}`
        wrapper.style.aspectRatio = `${image.naturalWidth} / ${image.naturalHeight}`
    }
}

const imageClick = (eventPhoto: EventPhoto, index: number) => {
    if (props.selectable) {
        albumStore.togglePhotoSelected(eventPhoto)
    } else {
        activeIndex.value = index
        displayGallery.value = true
    }
}

const isImageSelected = (image: EventPhoto) => {
    return props.selectable && albumStore.isPhotoSelected(image)
}

const isImageFavorited = (image: EventPhoto) => {
    return favoritesStore.isPhotoFavorited(image)
}

const addToAlbum = (image: EventPhoto) => {
    downloadImage(image)
}

const addToFavourites = (image: EventPhoto) => {
    favoritesStore.toggleFavorite(image)
}

watch(
    () => props.images,
    (images) => {
        if (!images || images.length === 0) return
        gridItems.value = images.map((image) => ({ spans: 1, image }))
        emit('imagesLoaded', images.length)
    },
    { immediate: true }
)
</script>

<template>
    <template v-if="props.images?.length === 0">
        <div
            class="flex flex-column flex-auto justify-content-center align-items-center"
        >
            <span class="text-2xl text-stone-800">No images to display</span>
        </div>
    </template>

    <template v-if="!selectable">
        <Galleria
            v-model:activeIndex="activeIndex"
            v-model:visible="displayGallery"
            :circular="true"
            :fullScreen="true"
            :numVisible="7"
            :showItemNavigators="true"
            :showThumbnails="true"
            :value="props.images"
            containerStyle="max-width: 50%"
        >
            <template #item="slotProps">
                <img
                    :src="getCdnUrl(slotProps.item.imagePath)"
                    alt="Image"
                    style="width: 100%; display: block"
                />
            </template>
            <template #thumbnail="slotProps">
                <img
                    :src="getCdnUrl(slotProps.item.thumbnailPath)"
                    alt="Image"
                    style="display: block"
                />
            </template>
        </Galleria>
    </template>

    <div class="image-grid">
        <div
            v-for="(item, index) of gridItems"
            :key="index"
            class="image-container"
        >
            <img
                :src="getCdnUrl(item.image.thumbnailPath)"
                alt="Image"
                class="gallery-image"
                @click="imageClick(item.image, index)"
                @load="(e) => onImageLoad(e, index)"
            />

            <div
                :class="isImageSelected(item.image) ? 'block' : 'hidden'"
                class="selected-check"
            >
                <span class="text-white pi pi-check text-xl"></span>
            </div>

            <div v-if="!selectable" class="image-options">
                <a
                    class="m-2.5 cursor-pointer"
                    @click="addToFavourites(item.image)"
                    title="Add to favourites"
                >
                    <span
                        class="pi text-2xl"
                        :class="
                            isImageFavorited(item.image)
                                ? 'pi-heart-fill text-red-500'
                                : 'text-white pi-heart'
                        "
                    ></span>
                </a>
                <a
                    class="m-2.5 cursor-pointer"
                    @click="addToAlbum(item.image)"
                    title="Add to album"
                >
                    <span class="text-white pi pi-download text-2xl"></span>
                </a>
            </div>
        </div>
    </div>
</template>

<style scoped>
.image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    gap: 8px;
    padding: 8px;
}

.image-container {
    position: relative;
    overflow: hidden;
    background-color: #f0f0f0;
    border-radius: 4px;
}

.gallery-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    transition: opacity 0.2s ease;
}

.gallery-image:hover {
    opacity: 0.85;
}

.image-options {
    display: none;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 25%;
    background: linear-gradient(to bottom, transparent, rgba(0, 0, 0, 0.8));
    padding: 8px;
    align-items: flex-end;
    justify-content: flex-start;
}

.image-container:hover .image-options {
    display: flex;
}

.selected-check {
    position: absolute;
    bottom: 8px;
    left: 8px;
    width: 40px;
    height: 40px;
    background-color: black;
    border: 2px solid white;
    border-radius: 50%;
    opacity: 0.85;
    display: flex;
    align-items: center;
    justify-content: center;
}
</style>
