import { generateClient, GraphQLResult } from 'aws-amplify/api'

// Define the client type explicitly instead of using ReturnType
// to avoid excessive stack depth when TypeScript tries to infer the type
type AmplifyGraphQLClient = {
    graphql: <T = Record<string, never>, O = Record<string, never>>(options: {
        query: string
        variables?: O
        authMode?: string
    }) => Promise<GraphQLResult<T>> | never
}

// Simplified type definitions with less nesting
type GeneratedQuery<InputType, OutputType> = string & {
    __generatedQueryInput: InputType
    __generatedQueryOutput: OutputType
}

type GeneratedMutation<InputType, OutputType> = string & {
    __generatedMutationInput: InputType
    __generatedMutationOutput: OutputType
}

type GeneratedSubscription<InputType, OutputType> = string & {
    __generatedSubscriptionInput: InputType
    __generatedSubscriptionOutput: OutputType
}

// Simplified union type
type UnionGeneratedQuery<InputType, OutputType> =
    | GeneratedQuery<InputType, OutputType>
    | GeneratedMutation<InputType, OutputType>
    | GeneratedSubscription<InputType, OutputType>

// Define auth modes type
export type GraphQLAuthMode = 'userPool' | 'oidc' | 'iam' | 'apiKey'

// Define response interface for consistent handling
export interface GraphQLResponse<T> {
    data: T | null
    error: Error | null
    success: boolean
}

// Type guard to check if an object matches the GraphQLResult interface
function isGraphQLResult<T>(obj: unknown): obj is GraphQLResult<T> {
    return (
        obj !== null &&
        typeof obj === 'object' &&
        'data' in obj &&
        (('errors' in obj &&
            Array.isArray((obj as { errors?: unknown }).errors)) ||
            !('errors' in obj))
    )
}

// Create a client with the specified auth mode
export function createGraphQLClient(
    authMode: GraphQLAuthMode = 'userPool'
): AmplifyGraphQLClient {
    return generateClient({
        authMode,
    }) as AmplifyGraphQLClient
}

// Default client with userPool auth
const defaultClient = createGraphQLClient()

/**
 * Execute a GraphQL operation with error handling
 * @param query The GraphQL query/mutation string
 * @param variables Variables for the operation
 * @param customClient Optional custom client (uses default if not provided)
 * @returns Structured response with data, error status, and success flag
 */
export async function executeGraphQL<I, O>(
    query: UnionGeneratedQuery<I, O>,
    variables?: I,
    customClient: AmplifyGraphQLClient = defaultClient
): Promise<GraphQLResponse<O>> {
    try {
        // Use explicit type assertion to avoid complex type inference
        const result = await customClient.graphql({
            query,
            variables: variables,
        })

        // Cast to simpler type to avoid excessive type checking
        const response = result as GraphQLResult<O>

        return {
            data: response.data || null,
            error: null,
            success: true,
        }
    } catch (error) {
        console.error('GraphQL operation failed:', error)

        // Check if the error is actually a GraphQLResult with errors
        if (isGraphQLResult<O>(error)) {
            return {
                data: error.data,
                error: new Error(
                    error.errors?.map((e) => e.message).join(', ') ||
                        'GraphQL error'
                ),
                success: true,
            }
        }

        return {
            data: null,
            error: error instanceof Error ? error : new Error(String(error)),
            success: false,
        }
    }
}

export async function execute<I, O>(
    query: UnionGeneratedQuery<I, O>,
    variables?: I,
    authMode?: GraphQLAuthMode
): Promise<GraphQLResponse<O>> {
    const client = authMode ? createGraphQLClient(authMode) : defaultClient
    return executeGraphQL<I, O>(query, variables, client)
}

/**
 * Subscribe to a GraphQL subscription
 * @returns Observable subscription
 */
export function subscribe<I, O>(
    subscription: GeneratedSubscription<I, O>,
    variables?: I,
    authMode?: GraphQLAuthMode
) {
    const client = authMode ? createGraphQLClient(authMode) : defaultClient
    // Use a simpler return type to avoid complex type inference
    return client.graphql({
        query: subscription,
        variables: variables,
    })
}
