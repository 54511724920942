<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { getCurrentUser } from 'aws-amplify/auth'
import NewEventModal from '@components/NewEventModal.vue'
import { useEventStore } from '@stores/EventStore.ts'

const createEventDialogVisible = ref(false)
const eventStore = useEventStore()

const username = ref<string | undefined>('')

onMounted(async () => {
    try {
        const currentUser = await getCurrentUser()
        username.value = currentUser.signInDetails?.loginId
    } catch (error) {
        console.error(error)
    }
})
</script>

<template>
    <NewEventModal
        v-model:visible="createEventDialogVisible"
        @eventCreated="(_) => eventStore.fetchEvents()"
    />

    <div class="flex w-full justify-content-between align-items-center">
        <h1
            class="mt-0 mb-0 text-2xl text-slate-500 font-normal w-1/2 overflow-hidden overflow-ellipsis"
        >
            Welcome back
            <br />{{ username }}
        </h1>
        <div class="flex gap-3 mt-3 md:mt-0">
            <router-link :to="{ name: 'EventList' }" class="no-underline">
                <Button
                    v-ripple
                    outlined
                    rounded
                    class="border-orange-400 text-orange-400 hover:bg-orange-50"
                    label="All events"
                >
                    <template #icon="{ class: className }">
                        <i :class="className + ' pi pi-calendar mr-2'"></i>
                    </template>
                </Button>
            </router-link>
            <Button
                @click="createEventDialogVisible = true"
                v-ripple
                rounded
                class=""
                label="New event"
            >
                <template #icon="{ class: className }">
                    <i :class="className + ' pi pi-plus mr-2'"></i>
                </template>
            </Button>
        </div>
    </div>
</template>

<style scoped></style>
