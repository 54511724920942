<script setup lang="ts">
import { onMounted, ref } from 'vue'
import { useToast } from 'primevue/usetoast'
import Toast from 'primevue/toast'
import { useEventStore } from '@stores/EventStore'
import { EventModel } from '@models/EventModel'

const isLoading = ref(true)

const eventStore = useEventStore()
const upcomingEvents = ref<EventModel[]>([])
const recentlyEndedEvents = ref<EventModel[]>([])
const toast = useToast()
const successToastVisible = ref(false)

onMounted(async () => {
    await fetchEvents()
})

const onClose = () => {
    successToastVisible.value = false
}

const fetchEvents = async () => {
    isLoading.value = true
    try {
        upcomingEvents.value = [...(await eventStore.fetchUpcomingEvents())]
        recentlyEndedEvents.value = [
            ...(await eventStore.fetchRecentlyEndedEvents()),
        ]
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Failed to load events',
            detail: 'Unable to load events. Please refresh the page.',
            life: 3000,
        })
    } finally {
        isLoading.value = false
    }
}
</script>

<template>
    <Toast position="bottom-center" group="editSuccess" @close="onClose">
        <template #message="slotProps">
            <div class="flex flex-column align-items-start" style="flex: 1">
                <span class="p-toast-summary flex align-items-center">
                    <span class="pi pi-exclamation-circle pr-1"></span>
                    {{ slotProps.message.summary }}
                </span>

                <div class="flex justify-evenly w-full my-2">
                    <router-link
                        :to="{
                            name: 'EventGallery',
                            params: {
                                eventId: slotProps.message.detail.data.eventId,
                            },
                        }"
                    >
                        <Button
                            v-ripple
                            class="p-button p-button-sm mt-2 ml-2 w-32"
                            label="View"
                        ></Button>
                    </router-link>

                    <router-link
                        :to="{
                            name: 'EventEdit',
                            params: {
                                eventId: slotProps.message.detail.data.eventId,
                            },
                        }"
                    >
                        <Button
                            v-ripple
                            class="p-button p-button-sm mt-2 ml-2 w-32"
                            label="Edit"
                        ></Button>
                    </router-link>
                </div>
            </div>
        </template>
    </Toast>
    <main class="min-h-screen">
        <section class="pt-5">
            <div class="mb-3">
                <h2 class="mt-0 mb-1 text-lg text-slate-500 font-normal">
                    Upcoming events
                </h2>
                <p class="text-sm text-slate-400 m-0">
                    View and manage your upcoming photoshoots and events
                </p>
            </div>

            <ProgressSpinner
                v-if="isLoading"
                class="w-full flex justify-center my-8"
            />

            <div v-else>
                <div
                    v-if="upcomingEvents.length > 0"
                    class="flex overflow-x-auto pb-4 gap-4 bg-white rounded-lg p-4 text-center"
                >
                    <article
                        v-for="(item, index) in upcomingEvents"
                        :key="index"
                        class="flex-none w-80 bg-amber-300 rounded-lg overflow-hidden transition-all duration-300 hover:shadow-xl hover:-translate-y-1 hover:brightness-105"
                    >
                        <router-link
                            :to="{
                                name: 'EventGallery',
                                params: { eventId: item.eventId },
                            }"
                            class="block no-underline text-current group"
                        >
                            <div class="aspect-[16/9] relative">
                                <img
                                    :src="
                                        item.eventImage ||
                                        '/api/placeholder/400/300'
                                    "
                                    alt="Event image"
                                    class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                                />
                                <div
                                    class="absolute inset-0 bg-black bg-opacity-20 flex flex-col items-center justify-center p-4 transition-all duration-300 group-hover:bg-opacity-30"
                                >
                                    <p
                                        class="text-white text-center text-xl font-bold uppercase mb-2"
                                    >
                                        {{ item.name }}
                                    </p>
                                    <p class="text-white text-center text-lg">
                                        {{ item.description }}
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </article>
                </div>
                <div v-else class="bg-white rounded-lg p-8 text-center">
                    <i class="pi pi-calendar text-4xl text-gray-400 mb-4"></i>
                    <h3 class="text-xl text-gray-900 mb-2">
                        No upcoming events
                    </h3>
                    <p class="text-gray-600">
                        Click "Create new event" to get started
                    </p>
                </div>
            </div>
        </section>
        <section class="pt-5">
            <div class="mb-3">
                <h2 class="mt-0 mb-1 text-lg text-slate-500 font-normal">
                    Recently ended events
                </h2>
                <p class="text-sm text-slate-400 m-0">
                    Access and review your completed events
                </p>
            </div>

            <div v-if="!isLoading">
                <div
                    v-if="recentlyEndedEvents.length > 0"
                    class="flex overflow-x-auto pb-4 gap-4 bg-white rounded-lg p-4 text-center"
                >
                    <article
                        v-for="(item, index) in recentlyEndedEvents"
                        :key="index"
                        class="flex-none w-80 bg-amber-300 rounded-lg overflow-hidden opacity-75 transition-all duration-300 hover:shadow-xl hover:-translate-y-1 hover:opacity-90 hover:brightness-105"
                    >
                        <router-link
                            :to="{
                                name: 'EventGallery',
                                params: { eventId: item.eventId },
                            }"
                            class="block no-underline text-current group"
                        >
                            <div class="aspect-[16/9] relative">
                                <img
                                    :src="
                                        item.eventImage ||
                                        '/api/placeholder/400/300'
                                    "
                                    alt="Event image"
                                    class="w-full h-full object-cover transition-transform duration-300 group-hover:scale-105"
                                />
                                <div
                                    class="absolute inset-0 bg-black bg-opacity-20 flex flex-col items-center justify-center p-4 transition-all duration-300 group-hover:bg-opacity-30"
                                >
                                    <p
                                        class="text-white text-center text-xl font-bold uppercase mb-2"
                                    >
                                        {{ item.name }}
                                    </p>
                                    <p class="text-white text-center text-lg">
                                        {{ item.description }}
                                    </p>
                                </div>
                            </div>
                        </router-link>
                    </article>
                </div>
                <div v-else class="bg-white rounded-lg p-8 text-center">
                    <i class="pi pi-clock text-4xl text-gray-400 mb-4"></i>
                    <h3 class="text-xl text-gray-900 mb-2">
                        No recently ended events
                    </h3>
                    <p class="text-gray-600">Past events will appear here</p>
                </div>
            </div>
        </section>
    </main>
</template>

<style scoped>
.aspect-video {
    aspect-ratio: 16 / 9;
}
</style>
