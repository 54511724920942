<script setup lang="ts">
import { computed } from 'vue'
import { useRoute } from 'vue-router'
import { useUIStore } from '@stores/UIStore.ts'

const route = useRoute()
const uiStore = useUIStore()

const currentHeaderComponent = computed(() => {
    return route.matched[route.matched.length - 1]?.components?.header || null
})
</script>

<template>
    <header
        :class="[
            'px-5 py-2 flex bg-white',
            uiStore.isSidebarCollapsed ? 'h-20' : 'h-28',
        ]"
    >
        <component :is="currentHeaderComponent"></component>
    </header>
</template>

<style scoped></style>
