import { defineStore } from 'pinia'
import { Album, AlbumPhoto, EventPhoto } from '../api.ts'
import { useScopedEventStore } from './EventStore.ts'
import {
    albumPhotosByAlbumId,
    albumsByEventId,
    getAlbum,
} from '../graphql/queries.ts'
import { createAlbum, createAlbumPhoto } from '../graphql/mutations.ts'
import { execute } from '@/utils/graphql.ts'

interface eventScopedAlbumState {
    selectedPhotosIds: string[]
    albums: Album[]
}

interface albumScopedAlbumState {
    album: Album | undefined
    albumPhotos: AlbumPhoto[]
    loading: boolean
}

export function useEventScopedAlbumStore(eventId: string) {
    const store = defineStore(`feature/albums::${eventId}`, {
        state: (): eventScopedAlbumState => ({
            selectedPhotosIds: [],
            albums: [],
        }),
        getters: {
            selectedPhotosCount: (state: eventScopedAlbumState) =>
                state.selectedPhotosIds.length,
            isPhotoSelected:
                (state: eventScopedAlbumState) => (photo: EventPhoto) => {
                    return state.selectedPhotosIds.includes(photo.id)
                },
            selectedPhotos: (state: eventScopedAlbumState) => {
                const eventStore = useScopedEventStore(eventId)
                return eventStore.eventPhotos.filter((photo) =>
                    state.selectedPhotosIds.includes(photo.id)
                )
            },
        },
        actions: {
            async fetchAlbums() {
                if (this.albums.length > 0) {
                    return this.albums
                }

                const response = await execute(albumsByEventId, {
                    eventId: eventId,
                    limit: 100,
                })

                if (
                    !response.success ||
                    !response.data ||
                    !response.data.albumsByEventId
                ) {
                    return []
                }

                const albums = response.data.albumsByEventId.items || []
                // Filter out null values and ensure they are of Album type
                const validAlbums = albums.filter(
                    (album): album is Album => album !== null
                )

                this.albums = validAlbums

                return this.albums
            },
            async createAlbum(name: string) {
                const response = await execute(createAlbum, {
                    input: {
                        name: name,
                        eventId: eventId,
                    },
                })

                if (!response.success || !response.data) {
                    throw new Error('Failed to create album')
                }

                this.albums.push(response.data.createAlbum as Album)

                return response.data.createAlbum
            },
            togglePhotoSelected(photo: EventPhoto) {
                if (this.selectedPhotosIds.includes(photo.id)) {
                    this.selectedPhotosIds = this.selectedPhotosIds.filter(
                        (p) => p !== photo.id
                    )
                } else {
                    this.selectedPhotosIds.push(photo.id)
                }
            },
            selectAllPhotos() {
                const eventStore = useScopedEventStore(eventId)
                this.selectedPhotosIds = eventStore.eventPhotos.map(
                    (photo) => photo.id
                )
            },
            clearSelectedPhotos() {
                this.selectedPhotosIds = []
            },
        },
    })

    return store()
}

export function useAlbumScopedAlbumStore(eventId: string, albumId: string) {
    const store = defineStore(`feature/albums::${eventId}::${albumId}`, {
        state: (): albumScopedAlbumState => ({
            album: undefined,
            albumPhotos: [],
            loading: false,
        }),
        getters: {},
        actions: {
            async fetchAlbum() {
                if (this.album) {
                    return this.album
                }

                this.loading = true

                const response = await execute(getAlbum, {
                    id: albumId,
                })

                if (
                    !response.success ||
                    !response.data ||
                    !response.data.getAlbum
                ) {
                    this.loading = false
                    throw new Error('Album not found')
                }

                this.album = response.data.getAlbum as Album

                this.loading = false

                return this.album
            },
            async fetchAlbumPhotos() {
                if (this.albumPhotos.length > 0) {
                    return this.albumPhotos
                }

                this.loading = true

                const response = await execute(albumPhotosByAlbumId, {
                    albumId: albumId,
                })

                if (
                    !response.success ||
                    !response.data ||
                    !response.data.albumPhotosByAlbumId
                ) {
                    this.loading = false
                    return []
                }

                const photos = response.data.albumPhotosByAlbumId.items || []
                // Filter out null values and ensure they are of AlbumPhoto type
                const validPhotos = photos.filter(
                    (photo): photo is AlbumPhoto => photo !== null
                )

                this.albumPhotos = validPhotos

                this.loading = false

                return this.albumPhotos
            },
            async addPhotosToAlbum(photoIds: string[]) {
                if (!photoIds.length) {
                    throw new Error('No photos to add')
                }

                this.loading = true

                try {
                    for (const photoId of photoIds) {
                        if (!photoId) {
                            continue
                        }

                        await execute(createAlbumPhoto, {
                            input: {
                                albumId: albumId,
                                eventPhotoAlbumPhotosId: photoId,
                            },
                        })
                    }
                } catch (error) {
                    console.error('Error adding photos to album:', error)
                    throw new Error('Failed to add photos to album')
                } finally {
                    this.loading = false
                }
            },
        },
    })

    return store()
}
