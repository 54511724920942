<script setup lang="ts">
import InputGroup from 'primevue/inputgroup'
import InputGroupAddon from 'primevue/inputgroupaddon'
import { toTypedSchema } from '@vee-validate/zod'
import * as zod from 'zod'
import { useField, useForm } from 'vee-validate'
import { ref, watch } from 'vue'
import { useToast } from 'primevue/usetoast'
import { useEventStore } from '@stores/EventStore.ts'
import { EventModel } from '@models/EventModel.ts'

const toast = useToast()
const eventStore = useEventStore()

const emits = defineEmits<{
    (e: 'eventCreated', event: EventModel): void
}>()

const visible = ref(false)

const validationSchema = toTypedSchema(
    zod.object({
        name: zod.string().trim().min(3).max(50),
        description: zod.string().trim().min(3).max(250),
    })
)

const { handleSubmit, errors, resetForm } = useForm({
    validationSchema,
})

const { value: name } = useField<string>('name')
const { value: description } = useField<string>('description')

watch(visible, () => {
    resetForm()
})

const onSubmit = handleSubmit(async ({ name, description }, { resetForm }) => {
    try {
        const newEvent = await eventStore.createEvent(name, description)

        toast.removeGroup('editSuccess')

        toast.add({
            severity: 'success',
            summary: 'Your event has been created!',
            detail: { data: newEvent },
            group: 'editSuccess',
        })

        visible.value = false

        resetForm()

        emits('eventCreated', EventModel.fromDTO(newEvent))
    } catch (error) {
        toast.add({
            severity: 'error',
            summary: 'Failed to create event',
            detail: 'Unable to create the event. Please try again later.',
            life: 3000,
        })
    }
})
</script>

<template>
    <Dialog
        v-model:visible="visible"
        appendTo="body"
        modal
        :breakpoints="{ '960px': '75vw', '640px': '100vw' }"
        :style="{ width: '52vw' }"
        :draggable="false"
        :resizable="false"
    >
        <template #header>
            <div class="flex flex-column gap-2">
                <h2 class="m-0 text-900 font-semibold text-xl line-height-3">
                    Create New Event
                </h2>
                <span class="text-600 text-base"
                    >Please enter a name and description for your new
                    event</span
                >
            </div>
        </template>

        <form
            id="newEventForm"
            class="flex flex-column gap-3 mt-3"
            @submit.prevent="onSubmit"
        >
            <div>
                <label for="event-name" class="block mb-1 text-color text-base"
                    >Event Name</label
                >
                <span class="p-input-icon-left w-full">
                    <InputGroup>
                        <InputGroupAddon>
                            <i class="pi pi-user"></i>
                        </InputGroupAddon>
                        <InputText
                            name="event-name"
                            type="text"
                            class="w-full"
                            id="event-name"
                            v-model="name"
                        />
                    </InputGroup>
                    <InlineMessage v-if="errors.name" class="mt-3">
                        {{ errors.name }}
                    </InlineMessage>
                </span>
            </div>

            <div>
                <label for="description" class="block mb-1 text-color text-base"
                    >Event Description</label
                >
                <span class="p-input-icon-left w-full">
                    <InputGroup>
                        <InputGroupAddon>
                            <i class="pi pi-credit-card"></i>
                        </InputGroupAddon>
                        <InputText
                            name="description"
                            type="text"
                            class="w-full"
                            id="description"
                            v-model="description"
                        />
                    </InputGroup>
                    <InlineMessage v-if="errors.description" class="mt-3">
                        {{ errors.description }}
                    </InlineMessage>
                </span>
            </div>
        </form>

        <template #footer>
            <div class="flex gap-3 justify-content-end surface-border pt-5">
                <Button
                    label="Cancel"
                    @click="visible = false"
                    class="p-button-text"
                ></Button>
                <Button
                    form="newEventForm"
                    label="Create"
                    type="submit"
                    class="p-button-rounded"
                ></Button>
            </div>
        </template>
    </Dialog>
</template>

<style scoped></style>
