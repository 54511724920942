import { defineStore } from 'pinia'
import { EventPhoto } from '../api.ts'
import { createUserFavorite, deleteUserFavorite } from '../graphql/mutations'
import { userFavoritesByEventId } from '../graphql/queries'
import { execute } from '@/utils/graphql.ts'

// Define the interface for user favorites state
interface UserFavoritesState {
    favoritePhotoIds: Record<string, string[]> // eventId -> array of photo IDs
    isLoading: boolean
    favoritesMap: Record<string, string> // photoId -> favoriteId (for deletion)
}

// Create the store for managing favorites
export const useUserFavoritesStore = defineStore('userFavorites', {
    state: (): UserFavoritesState => ({
        favoritePhotoIds: {},
        isLoading: false,
        favoritesMap: {},
    }),

    getters: {
        // Get favorite photos for a specific event
        getFavoritesByEventId:
            (state: UserFavoritesState) => (eventId: string) => {
                return state.favoritePhotoIds[eventId] || []
            },

        // Check if a photo is favorited
        isPhotoFavorited:
            (state: UserFavoritesState) => (photo: EventPhoto) => {
                const eventId = photo.eventId
                const photoIds = state.favoritePhotoIds[eventId] || []
                return photoIds.includes(photo.id)
            },

        // Get the count of favorites for an event
        getFavoritesCountByEventId:
            (state: UserFavoritesState) => (eventId: string) => {
                return (state.favoritePhotoIds[eventId] || []).length
            },
    },

    actions: {
        // Load favorites for an event from the backend
        async loadFavorites(eventId: string) {
            this.isLoading = true
            try {
                const response = await execute(userFavoritesByEventId, {
                    eventId: eventId,
                    limit: 1000,
                })

                if (response.success && response.data) {
                    const favorites =
                        response.data.userFavoritesByEventId?.items || []

                    // Initialize the arrays if needed
                    if (!this.favoritePhotoIds[eventId]) {
                        this.favoritePhotoIds[eventId] = []
                    }

                    // Clear existing favorites for this event
                    this.favoritePhotoIds[eventId] = []

                    // Add each favorite to the local store
                    favorites.forEach((favorite) => {
                        if (favorite) {
                            this.favoritePhotoIds[eventId].push(
                                favorite.eventPhotoId
                            )
                            this.favoritesMap[favorite.eventPhotoId] =
                                favorite.id
                        }
                    })
                }
            } catch (error) {
                console.error('Error loading favorites:', error)
            } finally {
                this.isLoading = false
            }
        },

        // Toggle favorite status for a photo
        async toggleFavorite(photo: EventPhoto) {
            const eventId = photo.eventId
            const photoId = photo.id

            // Check if photo is already favorited
            const isFavorited = this.isPhotoFavorited(photo)

            try {
                if (isFavorited) {
                    // Remove from favorites
                    const favoriteId = this.favoritesMap[photoId]
                    const response = await execute(deleteUserFavorite, {
                        input: {
                            id: favoriteId,
                        },
                    })

                    // Update local state if successful
                    if (response.success) {
                        this.favoritePhotoIds[eventId] = this.favoritePhotoIds[
                            eventId
                        ].filter((id) => id !== photoId)
                        delete this.favoritesMap[photoId]
                    }
                } else {
                    // Add to favorites
                    const response = await execute(createUserFavorite, {
                        input: {
                            eventId: eventId,
                            eventPhotoId: photoId,
                        },
                    })

                    // Initialize array for this event if it doesn't exist
                    if (!this.favoritePhotoIds[eventId]) {
                        this.favoritePhotoIds[eventId] = []
                    }

                    // Update local state if successful
                    if (response.success && response.data?.createUserFavorite) {
                        this.favoritePhotoIds[eventId].push(photoId)
                        this.favoritesMap[photoId] =
                            response.data.createUserFavorite.id
                    }
                }
            } catch (error) {
                console.error('Error toggling favorite:', error)
            }
        },

        // Get favorite photos as EventPhoto objects (needs photos array to filter)
        getFavoritePhotos(eventId: string, allPhotos: EventPhoto[]) {
            const favoriteIds = this.favoritePhotoIds[eventId] || []
            return allPhotos.filter((photo) => favoriteIds.includes(photo.id))
        },

        // Clear all favorites for an event
        async clearFavorites(eventId: string) {
            if (this.favoritePhotoIds[eventId]) {
                try {
                    // Delete each favorite from the backend
                    for (const photoId of this.favoritePhotoIds[eventId]) {
                        const favoriteId = this.favoritesMap[photoId]
                        const response = await execute(deleteUserFavorite, {
                            input: {
                                id: favoriteId,
                            },
                        })

                        if (response.success) {
                            delete this.favoritesMap[photoId]
                        }
                    }

                    // Clear local state
                    this.favoritePhotoIds[eventId] = []
                } catch (error) {
                    console.error('Error clearing favorites:', error)
                }
            }
        },
    },
})
