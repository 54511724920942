import { defineStore } from 'pinia'

interface UIState {
    isSidebarCollapsed: boolean
    isMobileMenuOpen: boolean
    theme: 'light' | 'dark'
    userPreferences: {
        notifications: boolean
        emailAlerts: boolean
    }
}

export const useUIStore = defineStore('ui', {
    state: (): UIState => ({
        isSidebarCollapsed: false,
        isMobileMenuOpen: false,
        theme: 'light',
        userPreferences: {
            notifications: true,
            emailAlerts: true,
        },
    }),
    getters: {
        sidebarWidth: (state) => (state.isSidebarCollapsed ? '5rem' : '18rem'),
        isDarkMode: (state) => state.theme === 'dark',
    },
    actions: {
        toggleSidebar() {
            this.isSidebarCollapsed = !this.isSidebarCollapsed
        },

        toggleMobileMenu() {
            this.isMobileMenuOpen = !this.isMobileMenuOpen
        },

        setTheme(theme: 'light' | 'dark') {
            this.theme = theme
            document.documentElement.classList.toggle('dark', theme === 'dark')
        },

        toggleTheme() {
            const newTheme = this.theme === 'light' ? 'dark' : 'light'
            this.setTheme(newTheme)
        },

        updateUserPreferences(
            preferences: Partial<UIState['userPreferences']>
        ) {
            this.userPreferences = {
                ...this.userPreferences,
                ...preferences,
            }
        },

        resetState() {
            this.$reset()
        },
    },
    persist: true,
})
