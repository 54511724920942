<script setup lang="ts">
import { useUIStore } from '@stores/UIStore.ts'

type NavigationLinkProps = {
    targetName: string
    icon: string
    label: string
}

const props = defineProps<NavigationLinkProps>()

const uiStore = useUIStore()
</script>

<template>
    <RouterLink
        v-ripple
        :to="{ name: props.targetName }"
        :class="[
            'sidebar-nav flex px-3 py-2 align-items-center mb-2 cursor-pointer text-black transition-duration-150 transition-colors p-ripple hover:bg-amber-100 hover:text-black',
        ]"
        exact-active-class="bg-amber-200 hover:bg-amber-200"
    >
        <i
            :class="[
                'pi text-2xl',
                uiStore.isSidebarCollapsed ? 'ml-2' : 'mr-2',
                `pi-${props.icon}`,
            ]"
        ></i>

        <span class="font-medium" v-if="!uiStore.isSidebarCollapsed">{{
            props.label
        }}</span>
    </RouterLink>
</template>

<style scoped></style>
