<script setup lang="ts">
import { EventModel } from '@models/EventModel.ts'
import { useScopedEventStore } from '@stores/EventStore.ts'
import { useUserFavoritesStore } from '@stores/UserFavoritesStore'
import { computed, onMounted, ref } from 'vue'
import Gallery from '@components/Gallery.vue'
import { GalleryType } from '@models/EventModel.ts'

type AlbumFavouriteProps = {
    event: EventModel
}

const props = defineProps<AlbumFavouriteProps>()
const eventStore = useScopedEventStore(props.event.eventId)
const favoritesStore = useUserFavoritesStore()
const isLoading = ref(true)

const favoritePhotos = computed(() => {
    return favoritesStore.getFavoritePhotos(
        props.event.eventId,
        eventStore.eventPhotos || []
    )
})

onMounted(async () => {
    isLoading.value = true

    // Load event photos if needed
    if (!eventStore.eventPhotos || eventStore.eventPhotos.length === 0) {
        await eventStore.fetchEventPhotos()
    }

    // Load favorites from backend
    await favoritesStore.loadFavorites(props.event.eventId)

    isLoading.value = false
    document.title = `Favorites - ${props.event.name} - Snap Pix`
})
</script>

<template>
    <template v-if="isLoading || eventStore.photosLoading">
        <div
            class="flex flex-column flex-auto justify-content-center align-items-center"
        >
            <ProgressSpinner></ProgressSpinner>
        </div>
    </template>
    <template v-else-if="favoritePhotos.length === 0">
        <div
            class="flex flex-column flex-auto justify-content-center align-items-center"
        >
            <span class="text-2xl text-stone-800">No favorite images yet</span>
            <p class="text-stone-600 mt-2">
                Add favorites by clicking the heart icon on images
            </p>
        </div>
    </template>
    <template v-else>
        <div class="flex flex-column flex-auto overflow-y-scroll">
            <div class="flex-auto bg-white-alpha-50 border-white-alpha-40">
                <div class="m-5">
                    <Gallery
                        :event="props.event"
                        :images="favoritePhotos"
                        :gallery-type="GalleryType.UNFIFORM"
                    ></Gallery>
                </div>
            </div>
        </div>
    </template>
</template>

<style scoped></style>
